import React, { useCallback, useEffect, useState } from 'react'
import Row from './Row'
import './Board.css'
import '../App.css'
import { Buffer } from 'buffer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isActualWord } from '../Utils/isActualWord';

const Board = ({ wordLength, guessAmount, actualWord, startNewGame }) => {
	const [activeCell, setActiveCell] = useState([0, 0]);
	const [currentGuess, setCurrentGuess] = useState('');
	const [rows, setRows] = useState(Array(guessAmount).fill(<Row />));
	const gameCode = Buffer.from(actualWord).toString('base64');

	const GameState = {
		Running: 1,
		Won: 2,
		Lost: 3,
	}

	const [gameState, setGameState] = useState(GameState.Running);

	useEffect(() => {
		function handleKeyDown(e) {
			if (e.keyCode === 13 && gameState !== GameState.Running) {
				startNewGame();
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
		}
	}, [gameState]);

	useEffect(() => {
		console.log("Word update");
		setGameState(GameState.Running);
		setCurrentGuess('');
		setActiveCell([0, 0])
		setRows(Array(guessAmount).fill(<Row />));

		return function cleanup() { }
	}, [actualWord]);

	function onCellInput(value) {
		if (value === '' || value === " ") return;
		setCurrentGuess(currentGuess + value)
		//console.log(`Cell input: ${value}, ${value.length}`)

		if (activeCell[1] === wordLength - 1) {
			if (activeCell[0] === 4) {
				//console.log("END");
			}
			else {
				//setActiveCell([activeCell[0] + 1, 0]);
			}
		}
		else {
			setActiveCell([activeCell[0], activeCell[1] + 1]);
		}

		//console.log("New Active Cell " + activeCell)
	}

	function onBackspace(soft) {
		if (!soft && activeCell[1] !== 0) {
			setActiveCell([activeCell[0], activeCell[1] - 1])
		}

		setCurrentGuess(currentGuess.substring(0, currentGuess.length - 1));
	}

	function onEnter() {
		//console.log(`Current Guess: ${currentGuess.toUpperCase()} | Actual Word: ${actualWord}`)
		if (currentGuess.toLowerCase() === actualWord.toLowerCase()) {
			console.log("WON");
			setGameState(GameState.Won);
			setActiveCell([-1, -1]);

			toast.success(`🎉 Congrats!`, {
				position: "bottom-center",
				autoClose: 1300,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
				icon: false,
			});
		}
		else {
			if (activeCell[0] === guessAmount - 1) {
				console.log("Failed");
				setGameState(GameState.Lost);
				setActiveCell([-1, -1]);

				toast.error(`🙁 Unlucky, the word was ${actualWord}.`, {
					position: "bottom-center",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
					icon: false,
				});
			}
			else {
				if (isActualWord(currentGuess)) {
					setActiveCell([activeCell[0] + 1, 0]);
					setCurrentGuess('');
				}
				else {
					toast.error(`⛔ ${currentGuess} is not a word!`, {
						position: "bottom-center",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'dark',
						icon: false,
					});
				}
			}
		}
	}

	function copyGameCode() {
		navigator.clipboard.writeText(`https://wordle.vbi.dev/${gameCode}`)

		toast.info('📋 Copied to clipboard!', {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'dark',
			icon: false
		});
	}

	return (
		<div className='flex-col flex-center gap'>
			<div className='info-message'>
				{
					gameState === GameState.Lost ? <h1>The word was {actualWord.toUpperCase()}😔, Press enter to play again</h1> : gameState === GameState.Won ? <h2>Congrats! Press enter to play again</h2> : <></>
				}
			</div>
			{rows.map((element, key) => (
				React.cloneElement(element, { row: key, wordLength: wordLength, activeCell: activeCell, onCellInput: onCellInput, onBackspace: onBackspace, onEnter: onEnter, currentGuess: currentGuess, actualWord: actualWord })
			))}
			<div className='flex-center flex-row'>
				<div className='game-code-label'>
					Game code is:
				</div>
				<div className='game-code' onClick={copyGameCode}>
					📋 {gameCode}
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default Board