import React, { useEffect, useState, version } from 'react'
import './Cell.css'
import '../App.css'

const Cell = ({ row, col, wordLength, activeCell, actualWord, onCellInput, onBackspace, onEnter }) => {
  const [cellValue, setCellValue] = useState('');

	useEffect(() => {
		setCellValue('');

		return function cleanup() { }
	}, [actualWord]);

  function validateInput(value) {
    if (value.trim().length === 1) {
      onCellInput(value);
    }
  }

  function handleKey(key) {
    if (key === "Backspace") {
      if (cellValue.length === 1) {
        //console.log(`Cell Value BSP: (${row}, ${col})`)
        setCellValue('')
        onBackspace(true)
      }
      else {
        onBackspace(false);
      }
    }
    else if (key === "Enter") {
      if (col === wordLength-1 && cellValue.length === 1) {
        onEnter();
      }
    }
  }

  function onGainFocus() {
    if (col !== wordLength-1 && cellValue.length === 1) {
      console.log("Clear it")
      setCellValue('')
    }
    else {
      //console.log("new cell")
    }
  }

  return (
    <div>{
      row === activeCell[0] && col === activeCell[1]
        ? <div className='flex-center'><input className='cell active' onFocus={onGainFocus()} ref={input => input && input.focus()} onKeyDown={(e) => handleKey(e.key)} value={cellValue} onChange={(e) => setCellValue(e.target.value.trim())} onInput={(e) => validateInput(e.target.value)} maxLength={1} autoFocus={true} onBlur={({ target }) => target.focus()}></input></div>
        : <div className='flex-center'><input className={`cell inactive ${cellValue.length === 1 && row !== activeCell[0] ? actualWord.toLowerCase().indexOf(cellValue.toLowerCase()) !== -1 ? cellValue === actualWord[col] ? 'correct' : 'contains' : 'wrong' : ''}`} value={cellValue} disabled={true}></input></div>
    }</div>
    // <div>{`(${row}, ${col})`}</div>
  )
}

export default Cell