import './App.css';
import Board from './GameObjects/Board';
import { Buffer } from 'buffer';
import { useLocation } from 'react-router-dom'
import { Wordles } from './Wordles';
import { useState } from 'react';
import { isActualWord } from './Utils/isActualWord';


function App() {
	const location = useLocation();

	function decodeGameCode() {
		const gameCodeBase64 = location.pathname.substr(1, location.pathname.length);
		const gameCode = Buffer.from(gameCodeBase64, 'base64').toString();

		return gameCode;
	}

	function getWordle() {
		const gameCode = decodeGameCode();

		if (isActualWord(gameCode)) {
			return gameCode;
		}
		else
		{
			const wordles = Wordles.Words;
			const wordle = wordles[Math.floor(Math.random() * wordles.length)];

			return wordle;
		}
	}

	function startNewGame() {
		setWordle(getWordle());
	}

	const [wordle, setWordle] = useState(getWordle());

	return (
		<div className="App flex-col flex-center">
			<h1 className='game-title'>Wordle</h1>
			<Board wordLength={wordle.length} guessAmount={wordle.length + 1} actualWord={wordle} startNewGame={startNewGame} />
		</div>
	);
}

export default App;
