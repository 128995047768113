import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";


function Navigator() {
   
    return (
        <Router>
        <div>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Routes>
                <Route path="*" element={<App />}>
                </Route>
            </Routes>
        </div>
        </Router>
    );
}

export default Navigator;