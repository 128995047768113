import React, { useEffect, useState } from 'react'
import Cell from './Cell'
import './Row.css'

const Row = ({ row, wordLength, activeCell, onCellInput, onBackspace, onEnter, currentGuess, actualWord }) => {
    const [cells, setCells] = useState(Array(wordLength).fill(<Cell />))
    
	useEffect(() => {
		setCells(Array(wordLength).fill(<Cell />));

		return function cleanup() { }
	}, [actualWord]);

    return (
        <div className='row gap'>
            {
                cells.map((element, key) => (
                    React.cloneElement(element, { row: row, col: key, wordLength: wordLength, activeCell: activeCell, currentGuess: currentGuess, actualWord: actualWord, onCellInput: onCellInput, onBackspace: onBackspace, onEnter: onEnter })
                ))
            }
        </div>
    )
}

export default Row